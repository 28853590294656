import React from 'react';
import Head from 'next/head';
import {corporatePhone} from '../../lib/globals';
import {
    removeAllHtmlTags,
    getFormattedDate,
    isValidJson,
} from 'aac-components/utils/helpers';

/*
 * Organization Schema
 * Templates: In Footer Template so that it will be on all Pages
 */
export const OrganizationSchema = (props) => {
    let schema;
    schema = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        description:
            "American Addiction Centers' mission is to provide quality treatment and innovative care to those with addictions. We help inspire the hope to recover. ",
        name: 'American Addiction Centers',
        url: 'https://americanaddictioncenters.org/',
        logo: 'https://americanaddictioncenters.org/static/aac_icon.svg',
        subOrganization: {
            name: [
                'AdCare Hospital',
                'AdCare Rhode Island',
                'Desert Hope Treatment Center',
                'Greenhouse Treatment Center',
                'Laguna Treatment Center',
                'Oxford Treatment Center',
                'Recovery First Treatment Center ',
                'Resolutions - Recovery Residences',
                'River Oaks Treatment Center',
            ],
        },
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: corporatePhone,
            contactType: 'customer service',
            contactOption: 'TollFree',
            areaServed: 'US',
            availableLanguage: 'en',
        },
        address: {
            '@type': 'PostalAddress',
            addressLocality: 'Brentwood',
            addressRegion: 'TN',
            postalCode: '37027',
            streetAddress: '500 Wilson Pike Circle, Suite 360',
        },
        sameAs: [
            'https://www.facebook.com/AmericanAddictionCenters/',
            'https://twitter.com/AAC_Tweet',
            'https://www.linkedin.com/company/american-addiction-centers/',
            'https://www.instagram.com/americanaddictioncenters/?hl=en',
            'https://www.youtube.com/c/Americanaddictioncenters',
            'https://en.wikipedia.org/wiki/American_Addiction_Centers',
        ],
    };
    schema = JSON.stringify(schema);
    return (
        <Head>
            <script
                id="organization-schema"
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: schema}}
            />
        </Head>
    );
};

export const StaffSchema = ({data = {}}) => {
    let schema = data?.acf?.aac_json_schema || '';
    let isValid = isValidJson(schema);
    if (!isValid) {
        return null;
    }
    if (schema && isValid) {
        schema = JSON.parse(schema);
        schema = JSON.stringify(schema);
    }
    return (
        <>
            {!!schema && isValid && (
                <Head>
                    <script
                        className="staff-schema"
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{__html: schema}}
                    />
                </Head>
            )}
        </>
    );
};

export const NewsSchema = ({data = {}}) => {
    const {
        title: {rendered: headline = ''} = {},
        excerpt: {rendered: description = ''} = {},
        modified = '',
        featured_image = [],
    } = data;

    let schema;
    schema = {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        headline: headline,
        description: removeAllHtmlTags(description),
        image: featured_image?.[3] || '',
        author: {
            '@type': 'Organization',
            name: 'American Addiction Centers',
            url: 'https://americanaddictioncenters.org/',
        },
        publisher: {
            '@type': 'Organization',
            name: 'American Addiction Centers',
            logo: {
                '@type': 'ImageObject',
                url: 'https://americanaddictioncenters.org/_next/image?url=%2Fstatic%2Faac_logo_vert_white.png&w=1920&q=75',
            },
        },
        datePublished: getFormattedDate(modified),
    };
    schema = JSON.stringify(schema);
    let isValid = isValidJson(schema);

    if (!isValid) {
        return null;
    }
    if (schema && isValid) {
        schema = JSON.parse(schema);
        schema = JSON.stringify(schema);
    }
    return (
        <>
            {!!schema && isValid && (
                <Head>
                    <script
                        id="news-schema"
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{__html: schema}}
                    />
                </Head>
            )}
        </>
    );
};
