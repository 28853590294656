import React from 'react';
import dynamic from 'next/dynamic';
import Layout from '../components/Layout'; // AAC2-3811
import Head from 'next/head';
import {OrganizationSchema} from '../components/Schema';
import EditorialPolicySection from '../components/Homepage/EditorialPolicySection';
import Canonical from '../components/Canonical';
import {WPFetch} from 'aac-components/utils/fetch';
import HomepageStyles from '../components/Homepage/HomepageStyles';

// dynamic imports
const Hero = dynamic(() => import('../components/Homepage/HeroSection'));
const LinkBankSection = dynamic(() => import('../components/Homepage/LinkBankSection'));
const MapSection = dynamic(() => import('../components/Homepage/MapSection'));

const AccreditationsSection = dynamic(() =>
    import('../components/Homepage/AccreditationsSection'),
);
const TreatmentOptionsSection = dynamic(() =>
    import('../components/Homepage/TreatmentOptionsSection'),
);
const StorySeciton = dynamic(() => import('../components/Homepage/StorySection'));
const InsuranceSection = dynamic(() => import('../components/Homepage/InsuranceSection'));
const TestimonialsSection = dynamic(() =>
    import('../components/Homepage/TestimonialsSection'),
);
const AddictionTalkSection = dynamic(() =>
    import('../components/Homepage/AddictionTalkSection'),
);

const Index = (props) => {
    return (
        <Layout prefooterbg="var(--tertiary-200)">
            {!props?.data?.yoast_head && (
                <Head>
                    <title key="title">
                        Resources for Addiction Rehabilitation & Recovery | American
                        Addiction Centers
                    </title>
                    <meta
                        name="title"
                        content="Resources for Addiction Rehabilitation & Recovery | American Addiction Centers"
                        className="next-head"
                    />
                    <meta
                        name="description"
                        content="American Addiction Centers' mission is to provide quality treatment and innovative care to those with addictions. We help inspire the hope to recover."
                        className="next-head"
                    />
                    <meta
                        name="robots"
                        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                    />
                </Head>
            )}
            <Canonical />
            <OrganizationSchema />
            <HomepageStyles>
                <div className="homepage-content">
                    <Hero {...props} />
                    <MapSection />
                    <LinkBankSection {...props} />
                    <AccreditationsSection />
                    <TreatmentOptionsSection {...props} />
                    <StorySeciton />
                    <InsuranceSection />
                    <EditorialPolicySection />
                    <TestimonialsSection />
                    <AddictionTalkSection />
                </div>
            </HomepageStyles>
        </Layout>
    );
};

export default Index;

export async function getServerSideProps(context) {
    const {clearCache = false} = context?.req?.query || false;

    let response = {};
    let data = [];
    try {
        const wpFetch = await WPFetch(
            `/wordpress/wp/v2/frontpage?_fields=yoast_head,id,acf.homepage_customizations,acf.content_tabs,acf.content_segment,title,content`,
            clearCache,
        );
        response = wpFetch.response;
        data = wpFetch.data;
    } catch (e) {
        console.warn(e);
    }

    const id = data?.id || 0;

    // set 404 status code
    if (id === 0) {
        context.res.statusCode = 404;
    }

    return {
        props: {
            data,
        },
    };
}
