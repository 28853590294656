import Head from 'next/head';
import {useRouter} from 'next/router';
import url from 'url';

const Canonical = () => {
    const {asPath} = useRouter();
    const {pathname} = url.parse(asPath);
    const canonical = `https://americanaddictioncenters.org${pathname}`;
    return (
        <Head>
            <link rel="canonical" key="canonical" href={canonical} />
        </Head>
    );
};
export default Canonical;
