import React, {useState} from 'react';

import {BREAKPOINT} from '../../lib/styles';
import {isSpanishPage} from '../../lib/utils';
import {useRouter, withRouter} from 'next/router';

import IconMedicalReview from 'aac-components/components/Icons/Misc/IconMedicalReview';
import SlideInLeftCta from 'aac-components/components/SlideInLeftCta';

const MedicallyReviewed = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const {
        query: {slug},
    } = useRouter();

    const hasReviewer = props?.bylineData?.reviewer || false;

    const isSpanish = isSpanishPage(slug);
    if (!hasReviewer) return null;

    const title = isSpanish
        ? 'Contenido revisado por un profesional de medicina'
        : 'Medically Reviewed';

    return (
        <div className={`medically-reviewed`}>
            <SlideInLeftCta isOpen={isOpen} handleClose={() => setIsOpen(false)}>
                {isSpanish && (
                    <div className="modal_content">
                        <div>
                            En American Addiction Centers, queremos dar información médica
                            actualizada y precisa para que nuestros lectores pueden tomar
                            decisiones acertadas sobre la salud.{' '}
                        </div>
                        <div>
                            Nuestros revisores son profesionales de salud con credenciales
                            que están especializados en el tratamiento de adicción de
                            drogas y asistencia médica del comportamiento.
                        </div>
                        <div>
                            Si usted tiene la impresión que nuestro contenido es
                            incorrecto, por favor envíenos un correo a{' '}
                            <a
                                href="mailto:ContactAAC@ContactAAC.com"
                                style={{fontWeight: 'bold'}}>
                                ContactAAC@ContactAAC.com
                            </a>
                            .{' '}
                        </div>
                    </div>
                )}
                {!isSpanish && (
                    <div className="modal_content">
                        <div>
                            At American Addiction Centers, we strive to provide the most
                            up-to-date and accurate medical information on the web so our
                            readers can make informed decisions about their healthcare.{' '}
                        </div>
                        <div>
                            Our reviewers are credentialed medical providers specializing
                            in addiction treatment and behavioral healthcare. We follow
                            strict guidelines when fact-checking information and only use
                            credible sources when citing statistics and medical
                            information. Look for the medically reviewed badge ({' '}
                            <IconMedicalReview /> ) on our articles for the most
                            up-to-date and accurate information.
                        </div>
                        <div>
                            If you feel that any of our content is inaccurate or
                            out-of-date, please let us know at{' '}
                            <a
                                href="mailto:ContactAAC@ContactAAC.com"
                                style={{fontWeight: 'bold'}}>
                                ContactAAC@ContactAAC.com
                            </a>
                            .{' '}
                        </div>
                    </div>
                )}
            </SlideInLeftCta>
            <div className="container" onClick={() => setIsOpen(true)}>
                <IconMedicalReview fill="var(--secondary-400)" />
                <div style={{marginLeft: '5px'}}>{title}</div>
            </div>
            <style jsx>
                {`
                    .medically-reviewed {
                        max-width: fit-content;
                        margin-bottom: 15px;
                        margin-right: 15px;
                        color: var(--secondary-400);
                        background: #fff;
                        border-radius: 2em;
                        position: relative;
                    }
                    .medically-reviewed:hover .container {
                        cursor: pointer;
                        transition: all 0.25s;
                        background: var(--secondary-100);
                    }
                    .container {
                        border: 1px solid var(--secondary-400);
                        padding: 5px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 2em;
                        font-size: 13px;
                        font-weight: bold;
                        position: relative;
                    }

                    .modal-inner {
                        position: relative;
                        height: 100vh;
                        display: flex;
                        z-index: 12;
                        align-items: center;
                        justify-content: center;
                    }
                    .card {
                        color: #000;
                        border-radius: 3px;
                        max-width: 300px;
                        padding: 20px;
                        text-align: left;
                        box-shadow: var(--box-shadow);
                        background: #fff;
                        max-height: 400px;
                        margin-top: -60px;
                        overflow-y: auto;
                        border: 2px solid #fff;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .card {
                            height: auto;
                            max-width: 600px;
                        }
                    }
                    .close {
                        margin-top: -10px;
                        float: right;
                    }
                    .close:hover {
                        cursor: pointer;
                    }
                    .modal_content {
                        padding-top: 20px;
                    }
                    .modal_content > div {
                        margin-bottom: 15px;
                    }
                `}
            </style>
        </div>
    );
};
export default withRouter(MedicallyReviewed);
